import React from 'react';
import { Modal, Input, Form, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const CreateSlideCard = ({ visible, onCreate, onCancel, onFileChange, fileList }) => {
  return (
    <Modal
      title="Create New Slide"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onCreate}
      >
        <Form.Item
          name="name"
          label="Slide Name"
          rules={[{ required: true, message: 'Please enter the slide name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Slide Description"
          rules={[{ required: true, message: 'Please enter the slide description!' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Upload Image">
          <Upload
            fileList={fileList}
            onChange={onFileChange}
            beforeUpload={() => false}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Create Slide
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateSlideCard;
