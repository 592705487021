import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'; // Remove BrowserRouter
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import Navbar from './components/Navbar'; // Import the Navbar
import LessonSlideManager from './components/LessonSlideManager'; // Import the component
import { isTokenExpired } from './utils/tokenUtils';

import './App.css'; // Import global styles

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Local state to track auth
  const navigate = useNavigate();

  // Check if the token is present on initial load
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Set authentication state based on the token
  }, []); // Only check for token once when component mounts

  useEffect(() => {
    // Function to check token validity
    const checkTokenValidity = () => {
      const token = localStorage.getItem('token');
      if (isTokenExpired(token)) {
        // Token is invalid or expired
        localStorage.removeItem('token'); // Remove the token from localStorage
        navigate('/login'); // Redirect to the login page
      }
    };

    // Check token validity every minute (60,000 milliseconds)
    const intervalId = setInterval(checkTokenValidity, 60000);

    // Perform initial check on component mount
    checkTokenValidity();

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, [navigate]);

  // Function to log the user in and update the authentication state
  const handleLogin = (token) => {
    localStorage.setItem('token', token); // Save the token to localStorage
    setIsAuthenticated(true); // Set authentication state to true after login
  };

  // Function to log the user out and update the authentication state
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    setIsAuthenticated(false); // Set authentication state to false after logout
  };

  return (
    <div className="app-container">
      {/* Render Navbar only if the user is authenticated */}
      {isAuthenticated && <Navbar onLogout={handleLogout} />} {/* Pass logout handler */}
      {/* Main content area */}
      <div className="main-content">
        <Routes>
          {/* Login Route */}
          <Route
            path="/login"
            element={!isAuthenticated ? <Login onLogin={handleLogin} /> : <Navigate to="/dashboard" />}
          />
          {/* Dashboard Route (Protected) */}
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          {/* Profile Route (Protected) */}
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
          />
          {/* Default Route */}
          <Route
            path="/"
            element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />}
          />
          <Route 
            path="/lessons" 
            element={<LessonSlideManager />} 
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
