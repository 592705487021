import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';
import { jwtDecode } from 'jwt-decode'; // Correct import
import LogsTable from './LogsTable';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decoded = jwtDecode(token); // Use jwtDecode instead of jwt_decode
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decoded.exp < currentTime) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        setUser(decoded);
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="content">
      <h1>Dashboard</h1>
      <div className="card">
        <h3>Welcome to your Dashboard</h3>
        <p>Here you can manage your lessons, view reports, and more.</p>
      </div>
      <div className="card">
        <h3>Recent Activity</h3>
        <LogsTable /> {/* Add the logs table here */}
      </div>
    </div>
  );
};

export default Dashboard;
