import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; // Separate CSS file for Navbar styling
import logo from '../assets/CompactLogo.png'; // Import your logo here

const Navbar = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout(); // Call onLogout to clear the token and update state
    window.location.href = '/login'; // Redirect to login page
  };

  return (
    <nav className="navbar">
      {/* Add the logo image */}
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      {/* Navigation Links */}
      <Link to="/dashboard">Dashboard</Link>
      <Link to="/lessons">Lessons</Link> 
      <Link to="/profile">Profile</Link>

      {/* Logout Button */}
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </nav>
  );
};

export default Navbar;
