import React from 'react';
import { Modal, Form, Input, Button } from 'antd';

const EditSlideCard = ({ visible, onEdit, onCancel, initialValues }) => {
  return (
    <Modal
      title="Edit Slide"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        initialValues={{
          name: initialValues?.name,
          description: initialValues?.description,
        }}
        onFinish={onEdit}
      >
        <Form.Item
          name="name"
          label="Slide Name"
          rules={[{ required: true, message: 'Please enter the slide name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Slide Description"
          rules={[{ required: true, message: 'Please enter the slide description!' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </Form>
    </Modal>
  );
};

export default EditSlideCard;
