import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Button, Card, notification } from 'antd';
import '../Login.css';
import logo from '../assets/CompactLogo.png'; // Import your logo here

const Login = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post('https://slides-api.bloxdesk.com/login', values);
      const { token } = response.data;
      
      if (token) {
        localStorage.setItem('token', token); // Store the token in localStorage
        window.location.reload(false);
      } else {
        throw new Error('Token not provided by server');
      }
    } catch (error) {
      notification.error({ message: 'Login failed', description: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <div className="login-logo">
          <img src={logo} alt="Logo" className="logo-image" />
          
        </div>
        <h2>Slides Management System</h2>
        <h2>Login</h2>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
