import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Button, notification } from 'antd';

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null); // State to hold user info
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch user info on component mount
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://slides-api.bloxdesk.com/profile', {
          headers: { Authorization: `Bearer ${token}` }, // Pass token in headers
        });
        setUserInfo(response.data); // Set user info in state
      } catch (error) {
        notification.error({ message: 'Failed to fetch user information.' });
      }
    };

    fetchUserInfo();
  }, []);

  // Handle password change form submission
  const onFinish = async (values) => {
    const { currentPassword, newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      notification.error({ message: 'Passwords do not match' });
      return;
    }

    setPasswordLoading(true);
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      await axios.post('https://slides-api.bloxdesk.com/change-password', {
        currentPassword,
        newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      notification.success({ message: 'Password changed successfully!' });
    } catch (error) {
      notification.error({
        message: 'Failed to change password',
        description: error.response ? error.response.data.message : 'Server error',
      });
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <div className="content">
      <h1>Profile</h1>

      {/* Display user info if available */}
      {userInfo ? (
        <div className="user-info">
          <h3>User Information</h3>
          <p><strong>Username:</strong> {userInfo.username}</p>
          {/* Add more fields if needed */}
        </div>
      ) : (
        <p>Loading user information...</p>
      )}

      <div className="change-password-section">
        <h3>Change Password</h3>
        <Form onFinish={onFinish}>
          <Form.Item
            name="currentPassword"
            rules={[{ required: true, message: 'Please enter your current password!' }]}
          >
            <Input.Password placeholder="Current Password" />
          </Form.Item>

          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: 'Please enter your new password!' }]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: 'Please confirm your new password!' }]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={passwordLoading}>
            Change Password
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
