export const isTokenExpired = (token) => {
    if (!token) return true; // If no token, treat it as expired
  
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) return true;
  
    try {
      const decodedPayload = JSON.parse(atob(tokenParts[1])); // Decode the payload
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decodedPayload.exp < currentTime; // Check if the token has expired
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };
  