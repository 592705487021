import React from 'react';
import { Modal, Input, Form, Button } from 'antd';

const EditLessonCard = ({ visible, onEdit, onCancel, initialValues }) => {
  return (
    <Modal
      title="Edit Lesson"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onEdit}
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label="Lesson Name"
          rules={[{ required: true, message: 'Please enter the lesson name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Lesson Description"
          rules={[{ required: true, message: 'Please enter the lesson description!' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="lesson_code"
          label="Lesson Code"
          rules={[{ required: true, message: 'Please enter the lesson code!' }]}
        >
          <Input />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </Form>
    </Modal>
  );
};

export default EditLessonCard;
