import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import axios from 'axios';

const LogsTable = () => {
  const [logs, setLogs] = useState([]);

  // Fetch logs from the backend
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get('https://slides-api.bloxdesk.com/logs', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setLogs(response.data);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
  }, []);

  // Helper function to format date as YYYY/MM/DD
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day
    return `${year}/${month}/${day}`;
  };

  // Define columns for the table
  const columns = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'date',
      render: (timestamp) => formatDate(timestamp), // Format the date as YYYY/MM/DD
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'time',
      render: (timestamp) => new Date(timestamp).toLocaleTimeString(), // Format the time
    },
    {
      title: 'Performed By',
      dataIndex: 'performed_by',
      key: 'performed_by',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Lesson Name',
      dataIndex: 'lesson_name',
      key: 'lesson_name',
      render: (lesson_name) => lesson_name || 'N/A', // Display 'N/A' if lesson name is null
    },
    {
      title: 'Slide Name',
      dataIndex: 'slide_name',
      key: 'slide_name',
      render: (slide_name) => slide_name || 'N/A', // Display 'N/A' if slide name is null
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h2>Action Logs</h2>
      <Table
        columns={columns}
        dataSource={logs}
        rowKey={(record) => record.id} // Use the primary key 'id' as the unique key
        pagination={{ pageSize: 10 }} // Paginate if too many logs
      />
    </div>
  );
};

export default LogsTable;
