import React from 'react';
import { Modal, Input, Form, Button } from 'antd';

const CreateLessonCard = ({ visible, onCreate, onCancel }) => {
  return (
    <Modal
      title="Create New Lesson"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onCreate}
      >
        <Form.Item
          name="name"
          label="Lesson Name"
          rules={[{ required: true, message: 'Please enter the lesson name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Lesson Description"
          rules={[{ required: true, message: 'Please enter the lesson description!' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="lesson_code"
          label="Lesson Code"
          rules={[{ required: true, message: 'Please enter the lesson code!' }]}
        >
          <Input />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Create Lesson
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateLessonCard;
