import React, { useState, useEffect } from 'react';
import { Table, Button, List, Card, notification } from 'antd';
import axios from 'axios';
import CreateLessonCard from './CreateLessonCard';
import EditLessonCard from './EditLessonCard';
import CreateSlideCard from './CreateSlideCard';
import EditSlideCard from './EditSlideCard';

const LessonSlideManager = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [slides, setSlides] = useState([]);
  const [isCreateLessonVisible, setCreateLessonVisible] = useState(false);
  const [isEditLessonVisible, setEditLessonVisible] = useState(false);
  const [isCreateSlideVisible, setCreateSlideVisible] = useState(false);
  const [isEditSlideVisible, setEditSlideVisible] = useState(false);
  const [editingLesson, setEditingLesson] = useState(null);
  const [editingSlide, setEditingSlide] = useState(null);
  const [fileList, setFileList] = useState([]); // Handle file uploads for slide images

  const fetchLessons = async () => {
    try {
      const response = await axios.get('https://slides-api.bloxdesk.com/lessons', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setLessons(response.data);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };
  useEffect(() => {
    fetchLessons();
  }, []);

  // Handle selecting a lesson and fetching its slides
  const handleSelectLesson = async (lesson) => {
    setSelectedLesson(lesson);
    try {
      const response = await axios.get(`https://slides-api.bloxdesk.com/lessons/${lesson.id}/slides`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setSlides(response.data);
    } catch (error) {
      console.error('Error fetching slides:', error);
      notification.error({ message: 'Failed to fetch slides' });
    }
  };

  // Handlers for opening and closing the modals
  const openCreateLessonModal = () => setCreateLessonVisible(true);
  const closeCreateLessonModal = () => setCreateLessonVisible(false);

  const openEditLessonModal = (lesson) => {
    setEditingLesson(lesson);
    setEditLessonVisible(true);
  };
  const closeEditLessonModal = () => setEditLessonVisible(false);

  const openCreateSlideModal = () => setCreateSlideVisible(true);
  const closeCreateSlideModal = () => setCreateSlideVisible(false);

  const openEditSlideModal = (slide) => {
    setEditingSlide(slide);
    setEditSlideVisible(true);
  };
  const closeEditSlideModal = () => setEditSlideVisible(false);

  // Handlers for adding and editing lessons
  const handleCreateLesson = async (values) => {
    try {
      const response = await axios.post('https://slides-api.bloxdesk.com/lessons', values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      notification.success({ message: 'Lesson created successfully!' });
      setLessons([...lessons, response.data]);
      setCreateLessonVisible(false);
      fetchLessons();
    } catch (error) {
      console.error('Error creating lesson:', error);
      notification.error({ message: 'Failed to create lesson' });
    }
  };

  const handleEditLesson = async (values) => {
    try {
      await axios.put(`https://slides-api.bloxdesk.com/lessons/${editingLesson.id}`, values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      notification.success({ message: 'Lesson updated successfully!' });

      // Update the lesson in the state
      setLessons(lessons.map((lesson) => (lesson.id === editingLesson.id ? { ...lesson, ...values } : lesson)));
      setEditLessonVisible(false);
    } catch (error) {
      console.error('Error editing lesson:', error);
      notification.error({ message: 'Failed to update lesson' });
    }
  };

  // Handlers for adding and editing slides
  const handleCreateSlide = async (values) => {
    const slideData = {
      name: values.name,
      description: values.description,
      created_by: localStorage.getItem('user_id'),
    };

    let imageUploadPath = null;

    // Check if there's an image to upload
    if (fileList.length > 0) {
      const imageData = new FormData();
      imageData.append('image', fileList[0].originFileObj);

      try {
        const imageUploadResponse = await axios.post(
          'https://slides-api.bloxdesk.com/upload-image',
          imageData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        imageUploadPath = imageUploadResponse.data.imagePath;
      } catch (error) {
        console.error('Error uploading image:', error);
        notification.error({ message: 'Failed to upload image' });
        return; // Exit if image upload fails
      }
    }

    // Add the image path to the slide data if it exists
    if (imageUploadPath) {
      slideData.image_path = imageUploadPath;
    }

    try {
      const response = await axios.post(
        `https://slides-api.bloxdesk.com/lessons/${selectedLesson.id}/slides`,
        slideData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      notification.success({ message: 'Slide created successfully!' });
      setSlides([...slides, response.data]);
      setCreateSlideVisible(false);
    } catch (error) {
      console.error('Error creating slide:', error);
      notification.error({ message: 'Failed to create slide' });
    }
  };

  const handleEditSlide = async (values) => {
    try {
      const slideData = {
        name: values.name,
        description: values.description,
      };

      await axios.put(`https://slides-api.bloxdesk.com/slides/${editingSlide.id}`, slideData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      notification.success({ message: 'Slide updated successfully!' });

      setSlides(
        slides.map((slide) => (slide.id === editingSlide.id ? { ...slide, ...values } : slide))
      );
      setEditSlideVisible(false);
    } catch (error) {
      console.error('Error editing slide:', error);
      notification.error({ message: 'Failed to edit slide' });
    }
  };

  // File upload handler for slides (only for Create Slide)
  const handleFileChange = ({ fileList }) => setFileList(fileList);

  // Reorder slides (Move up or down)
  const handleReorderSlide = async (slideId, direction) => {
    const slideIndex = slides.findIndex((slide) => slide.id === slideId);
    const newOrder = [...slides];

    // Prevent out-of-bound reordering
    if (
      (direction === 'up' && slideIndex === 0) ||
      (direction === 'down' && slideIndex === slides.length - 1)
    ) {
      return; // Don't move out of bounds
    }

    // Swap slides based on the direction
    const swapIndex = direction === 'up' ? slideIndex - 1 : slideIndex + 1;
    [newOrder[slideIndex], newOrder[swapIndex]] = [newOrder[swapIndex], newOrder[slideIndex]];

    // Optimistically update the frontend with the new order
    setSlides(newOrder);

    try {
      await axios.post(`https://slides-api.bloxdesk.com/lessons/${selectedLesson.id}/slides/reorder`, {
        slides: newOrder.map((slide, index) => ({ id: slide.id, order_number: index })),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      notification.success({ message: 'Slide order updated successfully!' });
    } catch (error) {
      console.error('Error reordering slides:', error);
      notification.error({ message: 'Failed to reorder slides' });
    }
  };

  // Handle deleting a lesson
  const handleDeleteLesson = async (lessonId) => {
    try {
      await axios.delete(`https://slides-api.bloxdesk.com/lessons/${lessonId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setLessons(lessons.filter((lesson) => lesson.id !== lessonId));
      setSlides([]); // Clear slides if lesson is deleted
      notification.success({ message: 'Lesson deleted successfully!' });
    } catch (error) {
      console.error('Error deleting lesson:', error);
      notification.error({ message: 'Failed to delete lesson' });
    }
  };

  // Handle deleting a slide
  const handleDeleteSlide = async (slideId) => {
    try {
      await axios.delete(`https://slides-api.bloxdesk.com/slides/${slideId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setSlides(slides.filter((slide) => slide.id !== slideId));
      notification.success({ message: 'Slide deleted successfully!' });
    } catch (error) {
      console.error('Error deleting slide:', error);
      notification.error({ message: 'Failed to delete slide' });
    }
  };

  // Table for lessons
  return (
    <div className="layout-container">
      <div className="content">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Lessons</h2>
          <Button type="primary" onClick={openCreateLessonModal}>
            Add Lesson
          </Button>
        </div>

        <Table
          columns={[
            {
              title: 'Lesson Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
            },
            {
              title: 'Lesson Code',
              dataIndex: 'lesson_code',
              key: 'lesson_code',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (text, lesson) => (
                <>
                  <Button
                    type="default"
                    onClick={() => handleSelectLesson(lesson)}
                    style={{ marginRight: '10px' }}
                  >
                    View Slides
                  </Button>
                  <Button type="primary" onClick={openCreateSlideModal} style={{ marginRight: '10px' }}>
                    Add Slide
                  </Button>
                  <Button type="primary" onClick={() => openEditLessonModal(lesson)} style={{ marginRight: '10px' }}>
                    Edit Lesson
                  </Button>
                  <Button type="danger" onClick={() => handleDeleteLesson(lesson.id)}>
                    Delete Lesson
                  </Button>
                </>
              ),
            },
          ]}
          dataSource={lessons}
          rowKey="id"
          pagination={false}
        />

        {/* Display slides when a lesson is selected */}
        {selectedLesson && slides.length > 0 && (
          <div style={{ marginTop: '20px' }}>
            <h3>Slides for {selectedLesson.name}</h3>
            <List
              dataSource={slides}
              renderItem={(slide) => (
                <List.Item>
                  <Card
                    title={slide.name}
                    cover={
                      slide.image_path ? (
                        <img
                          alt="slide-preview"
                          src={`https://slides-api.bloxdesk.com/get-image/${slide.image_path}`}
                          style={{ maxHeight: '200px', objectFit: 'cover' }}
                        />
                      ) : (
                        <div style={{ height: '200px', backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          No Image Available
                        </div>
                      )
                    }
                  >
                    <p>{slide.description}</p>
                    <Button onClick={() => handleReorderSlide(slide.id, 'up')} style={{ marginRight: '10px' }}>
                      Move Up
                    </Button>
                    <Button onClick={() => handleReorderSlide(slide.id, 'down')} style={{ marginRight: '10px' }}>
                      Move Down
                    </Button>
                    <Button onClick={() => openEditSlideModal(slide)} style={{ marginRight: '10px' }}>
                      Edit
                    </Button>
                    <Button type="danger" onClick={() => handleDeleteSlide(slide.id)}>
                      Delete
                    </Button>
                  </Card>
                </List.Item>
              )}
            />
          </div>
        )}

        {/* Modals for managing lessons and slides */}
        <CreateLessonCard visible={isCreateLessonVisible} onCreate={handleCreateLesson} onCancel={closeCreateLessonModal} />
        <EditLessonCard visible={isEditLessonVisible} onEdit={handleEditLesson} onCancel={closeEditLessonModal} initialValues={editingLesson} />
        <CreateSlideCard visible={isCreateSlideVisible} onCreate={handleCreateSlide} onCancel={closeCreateSlideModal} fileList={fileList} onFileChange={handleFileChange} />
        <EditSlideCard visible={isEditSlideVisible} onEdit={handleEditSlide} onCancel={closeEditSlideModal} initialValues={editingSlide} />
      </div>
    </div>
  );
};

export default LessonSlideManager;
